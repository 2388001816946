import { Container } from '@everlywell/leaves';
import FeaturedTestsSection from 'components/MembershipFeaturedTestSection';
import { TrackVisibilityWrapper } from 'components/TrackVisibilityWrapper';
import React from 'react';
import { ANALYTICS } from 'utils/constants/analytics';
import { FEATURED_TESTS } from 'utils/constants/dataTest';
import useFeaturedProducts from 'utils/hooks/useFeaturedProducts';
import { ContentWithImage } from 'utils/types';

import * as S from './styles';

export type FeaturedTestsContainerProps = {
  content: ContentWithImage;
  isMember: boolean;
  loggedIn: boolean;
};

const FeaturedTestsContainer = ({
  content,
}: FeaturedTestsContainerProps): JSX.Element | null => {
  const slugs =
    content.listOfContentWithImages?.map((prod) => prod.identifier) || [];

  const products = useFeaturedProducts(slugs);
  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.FEATURED_TESTS_SECTION.label}
      slug={content.slug}
    >
      <S.Wrapper id={content.slug} data-testid={FEATURED_TESTS.CONTAINER}>
        <Container>
          <FeaturedTestsSection products={products} />
        </Container>
      </S.Wrapper>
    </TrackVisibilityWrapper>
  );
};

export default FeaturedTestsContainer;
