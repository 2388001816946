import { graphql, useStaticQuery } from 'gatsby';
import { logError } from 'utils/helpers';
import { Product } from 'utils/types/spreeProducts';
const useFeaturedProducts = (slugs: string[]): Product[] => {
  try {
    if (!slugs?.length) return [];
    const { allSpreeProduct } = useStaticQuery(graphql`
      query {
        allSpreeProduct {
          nodes {
            slug
            productId
            contentfulProductInfo {
              productCardImage {
                gatsbyImageData(placeholder: BLURRED, quality: 75)
              }
              boxImage {
                description
                gatsbyImageData(placeholder: BLURRED)
              }
              displayName
              slug
              collectionMethods {
                ... on ContentfulCollectionMethod {
                  id
                  name
                }
              }
              symptomList {
                symptoms {
                  title
                }
              }
              shortDescription
              medicalDisclaimer {
                medicalDisclaimer
              }
              category {
                title
                id
              }
            }
            master_variant_id
            variants {
              price
              is_master
              id
            }
            sku
          }
        }
      }
    `);

    return allSpreeProduct?.nodes
      ? allSpreeProduct?.nodes.filter((node: Product) =>
          slugs.includes(node.slug),
        )
      : [];
  } catch (err) {
    logError(
      `There was an issue fetching and filtering Membership featured products`,
      {
        component: 'useFeaturedProducts',
        function: 'useFeaturedProducts',
        stackTrace: err,
      },
    );
    return [];
  }
};
export default useFeaturedProducts;
