import {
  Button as RawButton,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import {
  GatsbyImage as Img,
  getSrc,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  border: 1px solid ${colors.green1};
  height: 320px;

  ${mediaQueries.forTabletVerticalDown} {
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    max-height: none;
    max-width: 312px;
  }
  ${mediaQueries.forPhoneOnly} {
    min-height: 560px;
  }
`;

export const Slide = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ImageWrapper = styled.div`
  width: 42%;
  align-items: center;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }
`;
type ImageContainerProps = { image: IGatsbyImageData };
export const ImageContainer = styled(Img)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: url(${(props: ImageContainerProps) => getSrc(props.image)})
      no-repeat,
    ${colors.green1};
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: hidden;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    height: auto;
  }
`;
export const Image = styled(Img)`
  width: auto;
  height: 100%;
  align-self: center;
  opacity: 0;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    height: auto;
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  padding: ${size.xl1}px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  ${mediaQueries.forPhoneOnly} {
    justify-content: flex-start;
    margin: ${size.md}px ${size.md}px ${size.lg}px ${size.md}px;
    padding: 0;
  }
`;

export const Category = styled.div`
  width: 100%;
  font-size: 11px;
  margin-bottom: ${size.xs1}px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
  color: ${colors.green3};
`;

export const TestName = styled.a`
  width: 100%;
  ${typography.buttonText};
  margin-bottom: ${size.md}px;
  color: ${colors.green5};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const TestDescription = styled.div`
  width: 100%;
  color: ${colors.gray4};
  ${typography.bodyTextSmall};
`;

export const SymptomsWrapper = styled.ul`
  margin: ${size.sm}px 0 ${size.sm}px ${size.md}px;
`;

export const Symptom = styled.li`
  margin-right: ${size.md}px;
  ${typography.captionText};
  ${mediaQueries.forPhoneOnly} {
    margin-bottom: ${size.xs3}px;
  }
`;

export const Button = styled(RawButton)`
  width: 100%;
  ${typography.buttonTextSmall};
  max-width: 277px;
  font-size: 1.1rem;
  color: ${colors.white};
  line-height: 1;
  display: inline-block;
  position: relative;

  ${mediaQueries.forTabletVerticalDown} {
    margin: 0 auto;
    max-width: none;
  }

  ${mediaQueries.forPhoneOnly} {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    max-width: 278px;
  }
`;
