import { breakpoints } from '@everlywell/leaves';
import FeaturedTest from 'components/FeaturedTest';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { FEATURED_TESTS } from 'utils/constants/dataTest';
import { logError, scrollToId } from 'utils/helpers';
import { getMembershipVariantId } from 'utils/helpers/membershipHelpers';
import { MembershipIntakeContext } from 'utils/hooks/useMembershipIntake/context';
import { TestPlanContext } from 'utils/hooks/useTestPlan/context';
import { TestPlanStatusIds } from 'utils/hooks/useTestPlan/types';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { MembershipTypeOptions } from 'utils/types';
import { Product } from 'utils/types/spreeProducts';
import * as S from './styles';

Swiper.use([Navigation, Pagination]);

type FeaturedTestsSection = {
  products: Product[];
};

const FeaturedTestsSection = ({
  products,
}: FeaturedTestsSection): JSX.Element | null => {
  if (!products) return null;
  const [_loading, _setLoading] = useState(false);

  const {
    userData: { isMember, loggedIn, activeCredits },
  } = useContext(UserContext);
  const { createTestPlanItem, setTestPlanActiveStatus } =
    useContext(TestPlanContext);
  const [_innerWidth, _setInnerWidth] = useState(window.innerWidth);
  const { membershipType } = useContext(MembershipIntakeContext);
  const hasCredits = activeCredits > 0;
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const onAddTestPlan = async (sku: string) => {
    const hash = `${
      membershipType == MembershipTypeOptions.current ? 'current' : 'control'
    }-membership-test-plan`;
    try {
      _setLoading(true);
      const membershipVariantId = getMembershipVariantId(sku, membershipType);
      if (membershipVariantId) {
        await createTestPlanItem(membershipVariantId);
        await analytics.track({
          event: ANALYTICS.EVENTS.CLICKED_BUTTON,
          data: {
            spree_variant_id: membershipVariantId,
            label: ANALYTICS.LABELS.TEST_PLAN.add.featuredTest,
            isMember,
          },
        });

        setTestPlanActiveStatus(TestPlanStatusIds.upcoming);
        scrollToId(hash);
      }
    } catch (err) {
      logError((err as Error).message, {
        component: 'FeaturedTestsSection',
        method: 'onAddTestPlan',
      });
    } finally {
      _setLoading(false);
    }
  };

  const trackSliderClick = async (next: boolean) => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.FEATURED_TESTS_SECTION.label,
        description: next
          ? ANALYTICS.LABELS.FEATURED_TESTS_SECTION.nextSlide
          : ANALYTICS.LABELS.FEATURED_TESTS_SECTION.prevSlide,
        isMember: Boolean(isMember),
        loggedIn: isMember ? true : loggedIn,
      },
    });
  };
  const onSwipe = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.SWIPE,
      data: {
        label: ANALYTICS.LABELS.FEATURED_TESTS_SECTION.label,
        description: ANALYTICS.LABELS.FEATURED_TESTS_SECTION.swipe,
        isMember: Boolean(isMember),
        loggedIn: isMember ? true : loggedIn,
      },
    });
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      if (_innerWidth !== window.innerWidth) _setInnerWidth(window.innerWidth);
    }, 1000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slidesPerView = _innerWidth <= breakpoints.forPhoneOnly ? 1.1 : 1;
  const singleSlide = products.length === 1;

  return (
    <S.Container data-testid={FEATURED_TESTS.SECTION}>
      <S.SectionName id="test-included-title">Featured Tests</S.SectionName>

      <S.SliderContainer>
        <ReactSwiper
          loop={!singleSlide}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          navigation={
            singleSlide
              ? false
              : {
                  prevEl: prevRef?.current,
                  nextEl: nextRef?.current,
                }
          }
          spaceBetween={0}
          slidesPerView={singleSlide ? 1 : slidesPerView}
          onSlideChange={() =>
            window.innerWidth <= breakpoints.forPhoneOnly && onSwipe()
          }
        >
          {products?.map((data) => (
            <SwiperSlide key={data.productId}>
              {() => (
                <FeaturedTest
                  {...data.contentfulProductInfo}
                  slug={data.contentfulProductInfo?.slug || ''}
                  sku={data.sku}
                  isMember={isMember}
                  onTestCTAClicked={onAddTestPlan}
                  disabled={_loading}
                  hasCredits={hasCredits}
                />
              )}
            </SwiperSlide>
          ))}
        </ReactSwiper>

        {!singleSlide && (
          <>
            {/* @ts-ignore */}
            <S.Prev ref={prevRef} onClick={() => trackSliderClick()}>
              <S.LeftIcon />
            </S.Prev>
            {/* @ts-ignore */}
            <S.Next ref={nextRef} onClick={() => trackSliderClick(true)}>
              <S.RightIcon />
            </S.Next>
          </>
        )}
      </S.SliderContainer>
    </S.Container>
  );
};

export default FeaturedTestsSection;
