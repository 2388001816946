import React, { useRef } from 'react';
import { FEATURED_TESTS } from 'utils/constants/dataTest';
import { ContentfulProductInfo } from 'utils/types';

import { APP_ROOT } from '../../utils/constants/urls';
import * as S from './styles';

export type Symptom = {
  title: string;
};

interface Props extends ContentfulProductInfo {
  sku: string;
  isMember: boolean;
  hasCredits: boolean;
  disabled: boolean;
  onTestCTAClicked: (sku: string) => void;
}

const FeaturedTest = ({
  sku,
  isMember,
  symptomList,
  shortDescription,
  productCardImage,
  displayName,
  category,
  onTestCTAClicked,
  disabled,
}: Props): JSX.Element | null => {
  const orderButtonRef = useRef(null);
  const ctaCopy = isMember ? 'Add to queue' : 'Learn more';

  const productURL = `${APP_ROOT}/products/${sku}`;

  return (
    <S.Wrapper data-testid={FEATURED_TESTS.SLIDE}>
      {productCardImage?.gatsbyImageData && (
        <S.ImageWrapper>
          <S.ImageContainer
            image={productCardImage.gatsbyImageData}
            alt={productCardImage.title || ''}
          ></S.ImageContainer>
        </S.ImageWrapper>
      )}
      <S.Content>
        {category?.title && <S.Category>{category?.title}</S.Category>}
        {displayName && (
          <S.TestName href={productURL}>{displayName}</S.TestName>
        )}
        {shortDescription && (
          <S.TestDescription>{shortDescription}</S.TestDescription>
        )}
        {Boolean(symptomList?.symptoms?.length) && (
          <S.SymptomsWrapper>
            {symptomList?.symptoms
              ?.filter((_: Symptom, i: number) => i < 3)
              .map((symptom: Symptom) => (
                <S.Symptom key={symptom.title}>{symptom.title}</S.Symptom>
              ))}
          </S.SymptomsWrapper>
        )}
        {ctaCopy && (
          <S.Button
            ref={orderButtonRef}
            appearance="primary"
            data-id={sku}
            onClick={() => onTestCTAClicked(sku)}
            isDisabled={disabled}
          >
            {ctaCopy}
          </S.Button>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default FeaturedTest;
