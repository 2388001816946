import {
  size,
  typography,
  Icons,
  colors,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';
// Swiper Carousel
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

export const Container = styled.div`
  box-sizing: border-box;
  margin-top: ${size.xl2}px;
  margin-bottom: ${size.xl6}px;
  overflow: hidden;

  ${mediaQueries.forTabletVerticalDown} {
    margin-bottom: 0;
  }

  ${mediaQueries.forPhoneOnly} {
    margin-top: 0;
    margin-bottom: ${size.xl4}px;
  }
`;

export const SectionName = styled.div`
  ${typography.overlineText};
  text-align: center;
  margin-bottom: ${size.xl1}px;
`;

export const SliderContainer = styled.div`
  position: relative;
  padding: 0 ${size.xl4}px ${size.xl1}px ${size.xl4}px;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
  z-index: 1;

  ${mediaQueries.forPhoneOnly} {
    padding: 0;
    max-width: 100%;
  }

  .swiper-container {
    height: 100%;

    ${mediaQueries.forTabletVerticalDown} {
      max-width: 324px;
    }

    ${mediaQueries.forPhoneOnly} {
      padding-bottom: 10%;
    }

    .swiper-wrapper {
      .swiper-slide {
        padding: 0 10px;
      }
    }
  }
  .swiper-pagination {
    display: none;
    ${mediaQueries.forPhoneOnly} {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 10px;

      .swiper-pagination-bullet {
        flex: 1;
        margin: 0 ${size.xs3}px;
        border-radius: 4px;
        max-width: 20px;
        height: 4px;
        opacity: 1;
        background: ${colors.green2};

        &.swiper-pagination-bullet-active {
          background: ${colors.teal4};
        }
      }
    }
  }
`;

const CarouselButtons = `
  background: none;
  z-index: 99;
  display: inline-flex;
  cursor: pointer;
  padding: 15.4px 17.5px;
  border: 1px solid rgb(214, 235, 221);
  border-radius: 1px;
  background: rgba(214, 235, 221, 0.3);
  cursor: pointer;
  top: 35%;

  ${mediaQueries.forPhoneOnly} {
    display: none;
  }
  
`;

const CarouselIcon = `
  color: ${colors.teal4};
  display: block;
  width: 11px;
  height: 15px;
`;

export const Next = styled.button`
  ${CarouselButtons};
  position: absolute;
  right: 0;
`;
export const Prev = styled.button`
  ${CarouselButtons};
  position: absolute;
  left: 0;
`;

export const LeftIcon = styled(Icons.LeftCaret)`
  ${CarouselIcon};
`;

export const RightIcon = styled(Icons.RightCaret)`
  ${CarouselIcon};
`;
